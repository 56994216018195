import HomePage from "./landings/_homePage";
import {graphql} from "gatsby";

export default HomePage

export const frontmatter = {
  pageMetaData: {
    refKey: "home",
    title: "All-in-one Website, Database, Storage and Application Backups Automation",
    description: "Automate backups for all your servers, websites, databases (MySQL, PostgreSQL, MongoDB, Redis) storage and applications. Connect any provider, quick recovery, and peace of mind.",
  }
}

export const pageQuery = graphql`
  query CaseStudiesListHomeQuery {
    posts: allMdx(
      filter: {frontmatter: {layout: {eq: "casestudy"}}},
      sort: {fields: frontmatter___date, order: ASC},
      limit: 3,
      ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            userQuote
            userRole
            userName
            userPhoto
            companyShortDescription
            date(formatString: "DD-MM-YYYY")
          }
        }
      }
    }
  }
`