import React, {useRef} from "react"
import {graphql, Link, Link as LinkBase} from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import VideoModal from './_videoModal';

const HomePage = ({data}) => {
  const videoRef = useRef(null);

  return (
    <Layout.Layout>
      <Layout.SEO refKey="home"/>
      <Layout.Hero
        type="primary"
        title={(<><span className="font-black"><span className="text-primary">Trust your backups</span><br/> & focus on building amazing things.</span></>)}
        subTitle={(
          <>Automate all your server, database and cloud storage backups.<br/>Trust that your backups are running fine!</>)}
        checkMarks={['Database backup', 'Server & Volume backup', 'Cloud Storage backup', 'SaaS App backup']}
        icons={[
          <Ui.Image filename="brands/icons/mysql-ico.png" alt="MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/github-ico.png" alt="GitHub backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/mongodb-ico.png" alt="MongoDB backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/redis-ico.png" alt="Redis backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOceanMySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/aws-ico.png" alt="AWS MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/azure-ico.png" alt="Azure MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/postgresql-ico.png" alt="PostgreSQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/google-cloud-ico.png" alt="Google Cloud MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/notion-ico.png" alt="Notion backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/more-ico.png" alt="Google Cloud MySQL backup" className="w-6"/>
        ]}
        cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your first backup" urlAtrr={{ sb_source: "website", sb_term: "home-hero" }} />)}
        illustration={(
          <div className="absolute w-full right transform rounded-xl shadow-xl"  onClick={() => videoRef.current.show()}>
            <span className="group absolute z-10 inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
              <div className="relative">
                <div className="rounded-full ring-indigo-200 ring-4 ring-w-20 h-20 bg-primary hover:scale-110 transform transition duration-200 cursor-pointer">
                  <svg className="h-20 w-20 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
                    <path
                      fill="white"
                      d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"></path>
                  </svg>
                </div>
              </div>
            </span>
            <Ui.Image className="roundex-xl" filename="simplebackups-hero-dashboard-new.png" alt="SimpleBackups dashboard, all your websites backups in one place"/>
          </div>
        )}
      />

      <Ui.Container>
        <div className="pb-16 md:pb-28">
          <Sections.JoinBrandsSection />
        </div>
      </Ui.Container>

      <Ui.Section color="alt">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center"><span>Automated <span className="text-primary">backup service</span></span></Ui.Typography>
            <p className="mb-6 text-center">Don't leave any data unprotected. Optimized backup without code, get started in minutes.</p>
            <div className="grid grid-cols-2 gap-6 md:grid-cols-5 mt-6 md:mt-24 md:max-w-6xl m-auto">
              <Link to="#server" className="flex flex-col justify-center text-center">
                <Ui.Image filename="server-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
                <span className="block mt-3 font-bold">Server & Folders<i className="fal fa-arrow-right text-sm ml-2"></i></span>
              </Link>
              <Link to="#database" className="flex flex-col justify-center text-center">
                <Ui.Image filename="db-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
                <span to="#database" className="block mt-3 font-bold">Database<i className="fal fa-arrow-right text-sm ml-2"></i></span>
              </Link>
              <Link to="#server-volume" className="flex flex-col justify-center text-center">
                <Ui.Image filename="snapshot-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
                <span to="#server-volume" className="block mt-3 font-bold">Server & Volume<i className="fal fa-arrow-right text-sm ml-2"></i></span>
              </Link>
              <Link to="#storage" className="flex flex-col justify-center text-center">
                <Ui.Image filename="storage-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
                <span to="#storage" className="block mt-3 font-bold">Storage<i className="fal fa-arrow-right text-sm ml-2"></i></span>
              </Link>
              <Link to="#saas" className="flex flex-col justify-center text-center">
                <Ui.Image filename="saas-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
                <span to="#saas" className="block mt-3 font-bold">SaaS App<i className="fal fa-arrow-right text-sm ml-2"></i></span>
              </Link>
            </div>
            <div className="flex items-center justify-center mx-auto mt-24">
              <Ui.VideoThumbnail author="Laurent" title="Watch how to create a backup" duration="3mins" actionText="Watch now →" action={() => videoRef.current.show()} />
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section>
        <Ui.Container>
          <Ui.Row>
            <Ui.FeatureRows items={
              [
                {
                  id: 'server',
                  preTitle: (<Ui.Image filename="server-p-icon.png" alt="Server backup" className="mx-auto mb-3" style={{'width': '50px'}}/>),
                  title: (<>Server & folders backup</>),
                  description: (
                      <p>
                        Establish which files and folders have to be backed up. Organise them by project and have a clear, centralized view of all your running backups.
                        <Ui.FeatureChecks items={['Technology agnostic', 'Bring your storage', 'Incremental backup']} />
                        <Ui.Link arrow="right" to="/server-backup/">More about server backup</Ui.Link>
                      </p>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-file-backup.png'
                    alt="Server, files and folder backup"
                    style={{maxWidth: 550}}
                  />)
                },
                {
                  id: 'database',
                  preTitle: (<Ui.Image filename="db-p-icon.png" alt="Database backup" className="mx-auto mb-3" style={{'width': '50px'}}/>),
                  title: (<>Database backup</>),
                  description: (
                    <>
                      <p>
                        Backup your databases with just a few clicks.
                        We support tunneling, Serverless backup, integration with all database-as-a-service solutions, custom flags and much more.
                        <div className="my-6">
                          <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Supported databases</p>
                          <ul className={`flex flex-wrap gap-4 max-w-md`}>
                              <li><Link to="/mysql-backup" aria-label="MySQL Backup"><Ui.Image filename={`brands/icons/mysql-ico.png`} alt={`MySQL backup`} className="w-7"/></Link></li>
                              <li><Link to="/postgresql-backup" aria-label={`PostgreSQL backup`}><Ui.Image filename={`brands/icons/postgresql-ico.png`} alt={`PostgreSQL backup`} className="w-7"/></Link></li>
                              <li><Link to="/mongodb-backup" aria-label={`MongoDB backup`} ><Ui.Image filename={`brands/icons/mongodb-ico.png`} alt={`MongoDB backup`} className="w-7"/></Link></li>
                              <li><Link to="/redis-backup" aria-label={`Redis backup`}><Ui.Image filename={`brands/icons/redis-ico.png`} alt={`Redis backup`} className="w-7"/></Link></li>
                          </ul>
                        </div>
                        <Ui.FeatureChecks items={['Backup streaming', 'Cloud Storage integration']} />
                        {/* <Ui.FeatureChecks items={['Incremental backup', 'Backup streaming', 'Cloud Storage integration']} /> */}
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-database-backup.png'
                    alt="Database backup"
                    style={{maxWidth: 450}}
                  />)
                },
                {
                  id: 'server-volume',
                  preTitle: (<Ui.Image filename="snapshot-p-icon.png" alt="Snapshot backup" className="mx-auto mb-3" style={{'width': '50px'}}/>),
                  title: (<>Server & Volume Snapshot</>),
                  description: (
                    <>
                      <p>
                        Connect any Provider, select the instances you want to backup and we take care of the rest.<br/>
                        <div className="my-6">
                          <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Supported providers</p>
                          <ul className={`flex flex-wrap gap-4 max-w-md`}>
                            <Sections.SnapshotProviderList/>
                          </ul>
                        </div>
                        <Ui.FeatureChecks items={['Droplet/Instance backup', 'Volume/Disk backup']} />
                        <Ui.Link arrow="right" to="/snapshots/">More about Snapshots</Ui.Link>
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-snapshot.png'
                    alt="Server and Volume Snapshot"
                    style={{maxWidth: 450}}
                  />)
                },
                {
                  id: 'storage',
                  preTitle: (<Ui.Image filename="storage-p-icon.png" alt="Storage backup" className="mx-auto mb-3" style={{'width': '50px'}}/>),
                  title: (<>Storage Backup & Migration</>),
                  description: (
                    <>
                      <p>
                        Secure your most precious data by scheduling and automating replication of your buckets and cloud storage to any provider.<br/>
                        <div className="my-6">
                          <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Supported providers</p>
                          <Sections.StorageProvidersList subject={'Backup'} />
                        </div>
                        <Ui.FeatureChecks items={['Connect any S3 provider', 'Connect via SFTP', 'Connect all major Cloud storage']} />
                        <Ui.Link arrow="right" to="/storage-backup/">More about Storage Replication</Ui.Link>
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-storage-backup.png'
                    alt="Storage and bucket Backup"
                    style={{maxWidth: 450}}
                  />)
                },
                {
                  id: 'saas',
                  preTitle: (<Ui.Image filename="saas-p-icon.png" alt="Database backup" className="mx-auto mb-3" style={{'width': '50px'}}/>),
                  title: (<>SaaS App Backup</>),
                  description: (
                    <>
                      <p>
                        Schedule backups of your most sensible SaaS App.<br/>Don't trust applications never go down and keep control of your data.<br/>
                        <div className="my-6">
                          <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Supported providers</p>
                          <Sections.SaasProviderList subject={'SaaS'} />
                        </div>
                        <Ui.Link arrow="right" to="/saas-backup/">More about Saas App Backup</Ui.Link>
                      </p>
                    </>
                  ),
                  image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-saas-app-backup-gitlab.png'
                    alt="Git backup"
                    style={{maxWidth: 450}}
                  />)
                },
              ]}></Ui.FeatureRows>

          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Sections.SectionGetStarted/>
      <Sections.SectionTestimonialExtended />
      <Sections.SectionLearn
        title={<>Extensive documentation & guides!<br/>Oh.. and humans you can actually reach.</>}
        text={<>Discover our articles and guides to learn more about our products and services.</>}
        tags={['home']}
      />

      <VideoModal ref={videoRef} videoSrc="https://www.youtube.com/embed/fRoaJ6XbJv0?si=YvHOmTmrAV3HLUAS&autoplay=1" />
    </Layout.Layout>
  )
}

export default HomePage